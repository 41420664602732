import { useObserver } from 'mobx-react-lite';
import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Image,
  LayoutChangeEvent,
  Linking,
} from 'react-native';
import { CUBE_RED } from '../../../Config/Color';
import { DefaultBookingUrl, SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../Config/Constant';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import { sessionRepository } from '../../../Domain/Repositories/SessionRepository';
import Fonts from '../../../Domain/Types/Fonts';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import BookIcon from '../../../Images/Icons/BookNowButton.png';

/**
 * Player Discount Props.
 */
interface PlayerDiscountProps
{
  /**
   * Screen height.
   */
  height: number;
}

/**
 * Renders the Player Discount sub-component.
 * @returns JSX.Element.
 */
export default function PlayerDiscount({
  height,
}: PlayerDiscountProps): JSX.Element
{
  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(SectionTypes.PlayerDiscount, event);
  };

  const onBookNowButtonSelected = async (): Promise<void> =>
  {
    let url = window.config.BOOKING_URL;

    if (url === undefined || url.trim() === '')
    {
      url = DefaultBookingUrl;
    }

    await Linking.openURL(url);
  };

  return useObserver(() => (
    <View onLayout={onLayout} style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={[styles.descriptionText, {
          fontSize: height * 0.043,
        }]}
        >
          Beat your score!
          {'\n'}
          Book a second visit today!
        </Text>

        <TouchableOpacity
          onPress={onBookNowButtonSelected}
          style={styles.bookNowButton}
        >
          <Image
            source={BookIcon}
            resizeMode="contain"
            style={styles.bookNowIcon}
          />

          <Text style={[styles.descriptionText, {
            color: 'white',
            fontSize: height * 0.017,
            fontFamily: Fonts.KlaptBold,
          }]}
          >
            Book Now
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  ));
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.3,
    width: '100%',
    backgroundColor: CUBE_RED(),
    justifyContent: 'center',
  },
  textContainer: {
    flex: 2,
    justifyContent: 'center',
  },
  descriptionText: {
    fontSize: SCREEN_WIDTH * 0.05,
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.DDinCondensedBold,
    color: 'white',
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    lineHeight: SCREEN_HEIGHT * 0.05,
  },
  discountText: {
    fontSize: SCREEN_WIDTH * 0.05,
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.DDinCondensedBold,
    color: 'white',
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    lineHeight: SCREEN_HEIGHT * 0.05,
  },
  discountValueText: {
    color: 'black',
    paddingLeft: 7,
  },
  bookNowButton: {
    width: '70%',
    height: '13%',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 17,
  },
  bookNowIcon: {
    width: '100%',
    height: '100%',
    aspectRatio: 1,
    position: 'absolute',
    alignSelf: 'center',
  },
});
