/**
 * Possible error codes.
 */
export enum ErrorCode
 {
    Unknown,

    // Storage
    StorageError,

    // Network
    NoInternet,

    // Model Validation Error
    BadRequest,

    // Validation Error
    NotFound,

    // Unknown user error
    UnknownUser,

    // For any other 4xx or 5xx error.
    RequestError,

    // SignalR connection failure.
    SignalRConnectionFailure,

    // Internal server error.
    ServerError,
 }

/**
  * Error class used to pass errors around the system
  */
export class UpError extends Error
{
  constructor(public errorCode: ErrorCode, public message: string)
  {
    super(message);
  }
}
