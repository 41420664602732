/**
 * The available fonts.
 */
enum Fonts
{
  KlaptBold = 'KlaptBold',
  KlaptMedium = 'KlaptMedium',
  DDin = 'DDin',
  DDinCondensed = 'DDinCondensed',
  DDinCondensedBold = 'DDinCondensedBold',
  DDinExp = 'DDinExp',
}

export default Fonts;
