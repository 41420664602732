/**
 * The available screen types.
 */
enum ScreenTypes
{
  IdleScreen = '',
  Redirect = 'Redirect',
}

export default ScreenTypes;
