import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  LayoutChangeEvent,
} from 'react-native';
import { CUBE_RED } from '../../../Config/Color';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../Config/Constant';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import Fonts from '../../../Domain/Types/Fonts';
import SectionTypes from '../../../Domain/Types/SectionTypes';

/**
 * Section Title Props.
 */
interface SectionTitleProps
{
  /**
   * Screen height.
   */
  height: number;

  /**
   * The Title.
   */
  title?: string;

  /**
   * The background color. Defaults to CUBE_RED.
   */
  backgroundColor?: string;

  /**
   * The section type.
   */
 sectionType: SectionTypes;
}

/**
 * Renders the Section title sub-component.
 * @returns JSX.Element.
 */
export default function SectionTitle({
  height,
  title = 'Global Leaderboard',
  backgroundColor = CUBE_RED(),
  sectionType,
}: SectionTitleProps): JSX.Element
{
  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(sectionType, event);
  };

  return (
    <View
      onLayout={onLayout}
      style={[styles.container, {
        backgroundColor,
      }]}
    >
      <View style={styles.textContainer}>
        <Text style={[styles.descriptionText, {
          fontSize: height * 0.043,
        }]}
        >
          {title}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.1,
    width: '100%',
    backgroundColor: CUBE_RED(),
    justifyContent: 'center',
  },
  textContainer: {
    flex: 2,
    justifyContent: 'center',
  },
  descriptionText: {
    fontSize: SCREEN_WIDTH * 0.05,
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.DDinCondensedBold,
    color: 'white',
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    lineHeight: SCREEN_HEIGHT * 0.05,
  },
});
