/**
 * The Attraction Type.
 */
enum AttractionType
{
  /**
   * TheCube.
   */
  TheCube = 'TheCube',

  /**
   * Putters.
   */
  Putters = 'Putters',
}

export default AttractionType;
