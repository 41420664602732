import { BaseSyntheticEvent } from 'react';
import { Dimensions, PixelRatio, Platform } from 'react-native';
import { URL_PREFIX } from '../../Config/Parameters';

/**
 * The Validated Url Result.
 */
export interface ValidatedUrlResult
{
  /**
   * Valid URL flag.
   */
  isValid: boolean;

  /**
   * The validated url.
   */
  url: string;

  /**
   * The token.
   */
  token: string;
}

/**
 * Utility service.
 */
export default class Utils
{
  /**
   * Regular Expression syntax for determining if the
   * url matches our standard.
   *
   * URL must follow the standard of TEMP followed by the id.
   * e.g. TEMP1, TEMP99, TEMP1000, etc.
   */
  private static Regex = new RegExp(`^${URL_PREFIX}[0-9]+$`);

  /**
   * Checks if the URL is valid.
   */
  public static async isValidUrl(): Promise<ValidatedUrlResult>
  {
    const result: ValidatedUrlResult = {
      isValid: false,
      url: '',
      token: '',
    };

    if (Platform.OS === 'web')
    {
      // Get the entire URL after the address and port and the first /. E.g. output
      // is /#/Cube1?abcdef.
      const url = window.location.hash.split('/')[1];

      // Get the query token, which is after the first ?. E.g. output
      // is ?abcdef.
      const query = `?${window.location.hash.split('?')[1]}`;

      // Remove the ? from the query to get the token. E.g. output
      // is abcdef.
      const token = query.replace('?', '');

      // Replace the entire URL with the query token to get the Cube id.
      const id = url.replace(query, '');

      // Check if the id matches our standard.
      if (!this.Regex.test(id))
      {
        return result;
      }

      result.isValid = true;
      result.url = id;
      result.token = token;

      return result;
    }

    return result;
  }

  /**
   * Disables the context menu when the user taps and hold on the screen.
   * @param event The touch event.
   */
  public static disableContextMenu(event: BaseSyntheticEvent<MouseEvent,
    EventTarget & HTMLDivElement, EventTarget>): void
  {
    // Ensures that any touch events does not go past
    // this rendered component.
    event.preventDefault();
  }

  /**
   * Adds a comma to every 3rd position in a number.
   *
   * e.g. 200000 => 200,000;
   * @param input The input number.
   */
  public static commafyEveryThirdNumber(input: number): string
  {
    return Math.round(input).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Validates if a given string is a JWT or not.
   * @param input The input string.
   */
  public static isJwt(input: string): boolean
  {
    const pattern = /(\w)*\\(?!\\)(\w)*\\(?!\\)(\w)*(?!\\)/g;

    return pattern.test(input);
  }

  public static normalize(size: number): number
  {
    const {
      scale: FONT_SCALE,
    } = Dimensions.get('window');

    const newSize = size * FONT_SCALE;
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}
