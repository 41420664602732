import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  LayoutChangeEvent,
} from 'react-native';
import { ResizeMode, Video } from 'expo-av';
import { useObserver } from 'mobx-react-lite';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../Config/Constant';
import Fonts from '../../../Domain/Types/Fonts';
import BackgroundVideo from '../../../Videos/background.mp4';
import AttractionType from '../../../Domain/Types/AttractionType';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import { sessionRepository } from '../../../Domain/Repositories/SessionRepository';

/**
 * Your Story Props.
 */
interface YourStoryProps
{
  /**
   * Current screen height.
   */
  height: number;

  /**
   * The date.
   */
  date?: string;

  /**
   * The attraction type.
   */
  attractionType?: AttractionType;
}

/**
 * Renders the Your Story Sub-Component.
 * @returns JSX.Element.
 */
export default function YourStory({
  height,
  date = new Date(Date.now()).toDateString(),
  attractionType = AttractionType.TheCube,
}: YourStoryProps): JSX.Element
{
  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(SectionTypes.YourStory, event);
  };

  return useObserver(() => (
    <View onLayout={onLayout} style={styles.container}>
      <Video
        style={styles.videoBackground}
        source={BackgroundVideo}
        resizeMode={ResizeMode.COVER}
        isLooping
        shouldPlay
        isMuted
        pointerEvents="none"
      />

      <View style={styles.verticalPadding} />

      <View style={styles.textContainer}>
        <Text style={[styles.theCubeText, {
          fontSize: height * 0.065,
        }]}
        >
          {attractionType === AttractionType.Putters ? 'Putters' : 'The Cube'}
        </Text>
      </View>

      <View style={styles.textContainer}>
        <Text style={[styles.yourStoryText, {
          fontSize: height * 0.05,
        }]}
        >
          Your Story
        </Text>
      </View>

      <View style={styles.textContainer}>
        <Text style={[styles.dateText, {
          fontSize: height * 0.025,
        }]}
        >
          {sessionRepository.sessionData.takeAwaySessionDate}
        </Text>
      </View>

      <View style={styles.verticalPadding} />
    </View>
  ));
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.3,
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  videoBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    aspectRatio: 1,
    alignSelf: 'center',
    opacity: 0.75,
  },
  theCubeText: {
    fontSize: SCREEN_WIDTH * 0.05,
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.DDinCondensedBold,
    color: 'white',
    zIndex: 1,
  },
  yourStoryText: {
    fontSize: SCREEN_WIDTH * 0.065,
    fontFamily: Fonts.DDinCondensedBold,
    textTransform: 'uppercase',
    alignSelf: 'center',
    color: 'white',
    zIndex: 1,
    paddingTop: '5%',
  },
  dateText: {
    fontFamily: Fonts.DDinCondensedBold,
    textTransform: 'uppercase',
    color: 'white',
    zIndex: 1,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  textContainer: {
    flex: 2,
    justifyContent: 'center',
  },
  verticalPadding: {
    flex: 3,
    justifyContent: 'center',
  },
});
