/**
 * The Section Types.
 */
enum SectionTypes
{
  Header = 'Header',
  YourStory = 'YourStory',
  Teams = 'Teams',
  BulletTime = 'BulletTime',
  CubePoints1 = 'CubePoints1',
  CubePoints2 = 'CubePoints2',
  CubePoints3 = 'CubePoints3',
  CubePoints4 = 'CubePoints4',
  CubePoints5 = 'CubePoints5',
  CubePoints6 = 'CubePoints6',
  CubePoints7 = 'CubePoints7',
  DailyLeaderboardSectionTitle = 'DailyLeaderboardSectionTitle',
  DailyLeaderboard = 'DailyLeaderboard',
  PlayerDiscount = 'PlayerDiscount',
  GlobalLeaderboardSectionTitle = 'GlobalLeaderboardSectionTitle',
  GlobalLeaderboard = 'GlobalLeaderboard',
  SocialSharing = 'SocialSharing',
}

export default SectionTypes;
