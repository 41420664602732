import { useEffect, useState } from 'react';

const SCROLL_EVENT_NAME = 'scroll';

/**
 * The scroll position type.
 */
type useGetScrollPositionType = {
  currentScrollPosition: number;
  relativeScrollPosition: number;
};

/**
 * Hook which returns the current scroll position.
 */
const useGetScrollPosition = (): useGetScrollPositionType =>
{
  const getScrollPositionPixels = (): number => window.scrollY;

  const getCurrentScreenHeightPixels = (): number =>
  {
    const { body } = document;
    const html = document.documentElement;

    /**
     * Calculate the maximum scroll height in pixels.
     */
    const maxScrollHeightPixels = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);

    return maxScrollHeightPixels;
  };

  const [currentScrollPosition, setCurrentScrollPosition] = useState(getScrollPositionPixels());
  const [relativeScrollPosition, setRelativeScrollPosition] = useState(
    getScrollPositionPixels() / getCurrentScreenHeightPixels(),
  );

  const onScrollPositionUpdated = (): void =>
  {
    setCurrentScrollPosition(getScrollPositionPixels());
    setRelativeScrollPosition(getScrollPositionPixels() / getCurrentScreenHeightPixels());
  };

  useEffect(() =>
  {
    window.addEventListener(SCROLL_EVENT_NAME, onScrollPositionUpdated);

    return (): void =>
    {
      window.removeEventListener(SCROLL_EVENT_NAME, onScrollPositionUpdated);
    };
  }, []);

  return { currentScrollPosition, relativeScrollPosition };
};

export default useGetScrollPosition;
