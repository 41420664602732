import React from 'react';
import {
  View,
  StyleSheet,
} from 'react-native';

/**
 * Side Text Props.
 */
interface SideTextProps
{
  /**
   * The children.
   */
  children?: JSX.Element;
}

/**
 * Renders the Side Text Component.
 * @returns JSX.Element.
 */
export default function SideText({
  children,
}: SideTextProps): JSX.Element
{
  return (
    <View style={styles.container}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
