/* eslint-disable no-new */
import { UpError } from '../Error/UpError';
import Logger from '../Logger/Logger';
import Session from '../Models/API/Session';
import { appSettingsRepository } from '../Repositories/AppSettingsRepository';
import { sessionRepository } from '../Repositories/SessionRepository';
import HttpService from './HttpService';

const logger = Logger.Create('UpHubService');

/**
 * Service for handling Up Hub requests.
 */
export default class UpHubService
{
  /**
   * Retrieves the Bullet Time data.
   */
  public static async getBulletTimeData(token: string): Promise<boolean>
  {
    let success = false;

    const url = `${window.config.TAKEAWAY_URL}/${token}.json`;

    await HttpService.tokenlessGet<Session>(url, true)
      .then(async (data: Session) =>
      {
        await sessionRepository.initialise(data);

        appSettingsRepository.dataRetrievalCompleted = {
          didComplete: true,
          failed: false,
        };

        success = true;
      })
      .catch((error: UpError) =>
      {
        logger.fatal(`Failed to retrieve the data due to the following error: ${JSON.stringify(error)}`);

        appSettingsRepository.dataRetrievalCompleted = {
          didComplete: true,
          failed: true,
        };

        success = false;
      });

    return success;
  }
}
