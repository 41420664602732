/* eslint-disable class-methods-use-this */
import { observable, decorate } from 'mobx';
import { Platform } from 'react-native';
import Logger from '../Logger/Logger';
import LocalStorageService from '../Services/LocalStorageService';

const logger = Logger.Create('SessionTokenRepository');

/**
 * Repository containing JWT access token.
 */
export class SessionTokenRepository
{
  /**
    * The token.
    */
  public token = ''

  protected sessionDataStorage: LocalStorageService<string> = new LocalStorageService<string>('SessionTokenRepository');

  /**
   * Loads all the data stored in local storage into memory.
   */
  public async initialise(): Promise<void>
  {
    const loadedToken = await this.sessionDataStorage.load();

    if (loadedToken !== undefined)
    {
      logger.info('Successfully loaded the saved token into memory!');
      this.token = loadedToken;
    }
  }

  /**
   * Update access token.
   * @param token
   */
  public async updateAccessToken(newToken: string): Promise<void>
  {
    this.token = newToken;
    await this.sessionDataStorage.save(this.token);

    logger.info(`Successfully saved the access token for ${Platform.OS}: ${newToken}`);
  }
}

decorate(SessionTokenRepository, {
  token: observable,
});

export const sessionTokenRepository = new SessionTokenRepository();
