import React, { useEffect } from 'react';
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useHistory } from 'react-router';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../Config/Constant';
import { appSettingsRepository } from '../../Domain/Repositories/AppSettingsRepository';
import Fonts from '../../Domain/Types/Fonts';
import ButtonBackground from '../../Images/Icons/BookNowButton.png';
import UrbanPlaygroundHeader from '../Main/components/UrbanPlaygroundHeader';

/**
 * App Redirect Screen
 * @returns JSX.Element
 */
export default function RedirectScreen(): JSX.Element
{
  const history = useHistory();

  useEffect(() =>
  {
    // Hide the splash screen.
    appSettingsRepository.dataRetrievalCompleted = {
      didComplete: true,
      failed: false,
    };
  }, []);

  const onGoBackButtonSelected = (): void =>
  {
    history.goBack();
  };

  return (
    <View style={styles.container}>
      <View style={styles.logo}>
        <UrbanPlaygroundHeader />
      </View>

      <Text style={styles.text}>
        Sorry, we could not find the page you were looking for.
        We suggest you return to the previous link again.
      </Text>

      <TouchableOpacity
        style={styles.tryAgainButton}
        onPress={onGoBackButtonSelected}
      >
        <Image
          source={ButtonBackground}
          resizeMode="contain"
          style={styles.tryAgainIcon}
        />

        <Text style={styles.tryAgainText}>
          Go back
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    height: Dimensions.get('screen').height,
    width: Dimensions.get('screen').width,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  text: {
    fontSize: SCREEN_WIDTH * 0.033,
    textAlign: 'center',
    color: 'white',
    width: '50%',
    fontFamily: Fonts.DDinCondensedBold,
    textTransform: 'uppercase',
  },
  tryAgainIcon: {
    width: '40%',
    height: '50%',
    aspectRatio: 1,
    position: 'absolute',
    alignSelf: 'center',
    tintColor: 'red',
  },
  tryAgainButton: {
    width: '70%',
    height: '13%',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 17,
    zIndex: 20,
  },
  tryAgainText: {
    fontSize: SCREEN_HEIGHT * 0.017,
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.KlaptBold,
    color: 'white',
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    lineHeight: SCREEN_HEIGHT * 0.05,
  },
});
