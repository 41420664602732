import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
  Text,
} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { GetFullSocialShareQuery, SCREEN_HEIGHT, ShareQuote } from '../../Config/Constant';
import LinkIcon from '../../Images/Icons/Link.png';
import TwitterIcon from '../../Images/Icons/Twitter.png';
import FacebookIcon from '../../Images/Icons/Facebook.png';
import EmailIcon from '../../Images/Icons/Email.png';
import WhatsappIcon from '../../Images/Icons/Whatsapp.png';
import ShareType from '../../Domain/Types/ShareType';
import Fonts from '../../Domain/Types/Fonts';

/**
 * Social Sharing Toolbar Props.
 */
interface SocialSharingToolbarProps
{
  /**
   * The current url.
   */
  currentUrl?: string;

  /**
   * The visibility of the toolbar.
   */
  opacity?: number;

  /**
   * The container width.
   */
  containerWidth?: string;

  /**
   * The container height.
   */
  containerHeight?: string;

  /**
   * The border radius.
   */
  borderRadius?: number;

  /**
   * The controlled height of this component.
   */
  height?: number;

  /**
   * Toggles the label.
   */
  showLabel?: boolean;

  /**
   * The background color. Defaults to black.
   */
  backgroundColor?: string;
}

/**
 * Renders the Social Sharing Toolbar shared sub-component.
 * @returns JSX.Element.
 */
export default function SocialSharingToolbar({
  currentUrl = window.location.href,
  opacity = 1,
  containerWidth,
  containerHeight = '50%',
  borderRadius = 0,
  height = SCREEN_HEIGHT,
  showLabel = false,
  backgroundColor = 'black',
}: SocialSharingToolbarProps): JSX.Element
{
  const onShare = (shareType: ShareType): void =>
  {
    const queryParameter = `${ShareQuote} ${encodeURIComponent(currentUrl)}`;
    let url = '';

    switch (shareType)
    {
      default:
      case ShareType.Facebook:
        // Facebook no longer takes in parameters in the URL
        // but instead scrapes the website's meta tags.
        // See index.html for tags.
        url = GetFullSocialShareQuery(ShareType.Facebook, encodeURIComponent(currentUrl));
        break;
      case ShareType.WhatsApp:
        url = GetFullSocialShareQuery(ShareType.WhatsApp, queryParameter);
        break;
      case ShareType.Twitter:
        url = GetFullSocialShareQuery(ShareType.Twitter, queryParameter);
        break;
      case ShareType.Email:
        url = GetFullSocialShareQuery(ShareType.Email, queryParameter);
        break;
      case ShareType.CopyLink:
        copyToClipboard();
        return;
    }

    if (url.trim() !== '')
    {
      Linking.openURL(url);
    }
  };

  const copyToClipboard = (): void =>
  {
    Clipboard.setString(currentUrl);
  };

  return (
    <View style={[styles.container, {
      opacity,
      width: containerWidth,
      height: containerHeight,
      borderRadius,
      backgroundColor,
    }]}
    >
      {showLabel && (
        <Text style={[styles.label, {
          fontSize: height * 0.015,
        }]}
        >
          Share On Social
        </Text>
      )}

      <TouchableOpacity
        onPress={(): void => onShare(ShareType.CopyLink)}
        style={styles.socialMediaButton}
      >
        <Image
          source={LinkIcon}
          resizeMode="contain"
          style={styles.socialMediaIcon}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={(): void => onShare(ShareType.Twitter)}
        style={styles.socialMediaButton}
      >
        <Image
          source={TwitterIcon}
          resizeMode="contain"
          style={styles.socialMediaIcon}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={(): void => onShare(ShareType.Facebook)}
        style={styles.socialMediaButton}
      >
        <Image
          source={FacebookIcon}
          resizeMode="contain"
          style={styles.socialMediaIcon}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={(): void => onShare(ShareType.Email)}
        style={styles.socialMediaButton}
      >
        <Image
          source={EmailIcon}
          resizeMode="contain"
          style={styles.socialMediaIcon}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={(): void => onShare(ShareType.WhatsApp)}
        style={styles.socialMediaButton}
      >
        <Image
          source={WhatsappIcon}
          resizeMode="contain"
          style={styles.socialMediaIcon}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '30%',
    height: '50%',
    backgroundColor: 'black',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  socialMediaButton: {
    width: undefined,
    height: '100%',
    aspectRatio: 1,
    marginLeft: 10,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  socialMediaIcon: {
    width: '50%',
    height: '50%',
    aspectRatio: 1,
    position: 'absolute',
    alignSelf: 'center',
  },
  label: {
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.KlaptMedium,
    color: 'white',
    zIndex: 1,
    textAlign: 'center',
    lineHeight: SCREEN_HEIGHT * 0.05,
  },
});
