/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import { observable, decorate } from 'mobx';
import { LayoutChangeEvent } from 'react-native';
import SectionSizes from '../Models/SectionSizes';
import SectionTypes from '../Types/SectionTypes';
import SectionRectangle from '../Models/SectionRectangle';
import ScrollWindow from '../Models/ScrollWindow';
import LocalStorageService from '../Services/LocalStorageService';

/**
 * Section height repository.
 */
export class SectionHeightRepository
{
  /**
   * All available sections and their offsets.
   */
  public sectionHeights: SectionSizes[] = [];

  /**
   * The sections in view.
  */
  public sectionsInView: SectionTypes[] = [];

  protected sectionHeightsStorage: LocalStorageService<SectionSizes[]> = new LocalStorageService<SectionSizes[]>('SectionHeightStorage');

  /**
   * Updates the height of the given section.
   */
  public updateSectionHeight(sectionType: SectionTypes, layoutChangeEvent: LayoutChangeEvent): void
  {
    const sectionRectangle: SectionRectangle = JSON.parse(
      JSON.stringify(layoutChangeEvent.nativeEvent.layout),
    );

    const sectionHeight: SectionSizes = {
      sectionType,
      offsetY: sectionRectangle.top,
    };

    const found = this.sectionHeights.find((x) => x.sectionType === sectionType);

    // No duplicate.
    if (found === undefined)
    {
      this.sectionHeights.push(sectionHeight);
      return;
    }

    // If there is, create a clone, update the item in the clone,
    // update the original list with the new updated clone.
    const clone = [...this.sectionHeights];
    const clonedItem = this.sectionHeights.find((x) => x.sectionType === sectionType);

    if (clonedItem === undefined)
    {
      this.sectionHeights.push(sectionHeight);
      return;
    }

    clonedItem.offsetY = sectionRectangle.top;
    this.sectionHeights = clone;
  }

  /**
   * Callback when the user has scrolled.
   */
  public onScollWindowUpdated(scrollWindow: ScrollWindow): void
  {
    // Get all the sections which are in the scroll window.
    const sections = this.sectionHeights.filter((x) => x.offsetY < scrollWindow.bottom
      && x.offsetY > scrollWindow.top);

    // Populate the array with only the section types from the section array.
    this.sectionsInView = sections.map((x) => x.sectionType);
  }
}

decorate(SectionHeightRepository, {
  sectionHeights: observable,
  sectionsInView: observable,
});

export const sectionHeightRepository = new SectionHeightRepository();
