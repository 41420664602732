import React, { useEffect } from 'react';
import { Dimensions, Platform } from 'react-native';
import * as RouterWeb from 'react-router-dom';
import * as RouterNative from 'react-router-native';
import ScreenTypes from './src/Domain/Types/ScreenTypes';
import MainScreen from './src/Components/Main/MainScreen';
import Logger from './src/Domain/Logger/Logger';
import SplashScreen from './src/Components/SplashScreen/SplashScreen';
import { appSettingsRepository } from './src/Domain/Repositories/AppSettingsRepository';
import RedirectScreen from './src/Components/RedirectScreen/RedirectScreen';

const logger = Logger.Create('Root');

/**
 * Platform specific imports.
 */
const Router = Platform.OS === 'web'
  ? RouterWeb.HashRouter
  : (RouterNative.NativeRouter as React.ElementType);
const Route = Platform.OS === 'web' ? RouterWeb.Route : RouterNative.Route;
const Switch = Platform.OS === 'web' ? RouterWeb.Switch : RouterNative.Switch;

/**
 * Top level implementation for handling the application navigation.
 * @returns JSX Element.
 */
export default function Root(): JSX.Element
{
  /**
   * Returns the navigation path which is platform dependant.
   * @param target Target screen to navigate to.
   * @returns Platform dependant absolute path
   */
  const getNavigationPath = (target: ScreenTypes): string =>
  {
    if (Platform.OS === 'web')
    {
      return `/:id/${target}`;
    }

    return `/${target}`;
  };

  useEffect(() =>
  {
    /**
     * Scroll to the top of the page
     * everytime the user refreshes the page.
     */
    window.onbeforeunload = (): void =>
    {
      window.scrollTo({
        top: 0,
      });
    };

    (async (): Promise<void> =>
    {
      logger.info('Initialising the application...');

      await appSettingsRepository.initialise(Dimensions.get('screen'));

      /**
       * Add any application startup requirements here...
       */

      logger.info('Successfully initialised the application!');
    })();
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={getNavigationPath(ScreenTypes.IdleScreen)}
          component={MainScreen}
        />
        <Route
          exact
          path={getNavigationPath(ScreenTypes.Redirect)}
          component={RedirectScreen}
        />
      </Switch>

      <SplashScreen />
    </Router>
  );
}
