import React from 'react';
import {
  View,
  StyleSheet,
  LayoutChangeEvent,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { SCREEN_HEIGHT } from '../../../Config/Constant';
import Leaderboard from '../../Shared/Leaderboard';
import { GLOBAL_LEADERBOARD_PALETTE } from '../../../Config/Color';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import { sessionRepository } from '../../../Domain/Repositories/SessionRepository';

/**
 * Renders the Global Leaderboard Sub-Component.
 * @returns JSX.Element.
 */
export default function GlobalLeaderboard(): JSX.Element
{
  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(SectionTypes.GlobalLeaderboard, event);
  };

  return useObserver(() => (
    <View onLayout={onLayout} style={styles.container}>
      <View style={styles.content}>
        <View style={styles.contentContainer}>
          <View style={styles.leaderboardContainer}>
            <Leaderboard
              entries={sessionRepository.getGlobalLeaderboard()}
              colorPalette={GLOBAL_LEADERBOARD_PALETTE}
              shouldAnimate={false}
            />
          </View>
        </View>
      </View>
    </View>
  ));
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.45,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    height: '100%',
    backgroundColor: GLOBAL_LEADERBOARD_PALETTE.background,
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  leaderboardContainer: {
    flex: 1,
    justifyContent: 'center',
  },
});
