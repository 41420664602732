import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  LayoutChangeEvent,
  Animated,
  Easing,
} from 'react-native';
import { CountUp } from 'use-count-up';
import { useObserver } from 'mobx-react-lite';
import { CUBE_RED } from '../../../Config/Color';
import { SCREEN_HEIGHT } from '../../../Config/Constant';
import Team from '../../../Domain/Models/API/Team';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import Fonts from '../../../Domain/Types/Fonts';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import Utils from '../../../Domain/Utils/Utils';
import CenterText from '../../Shared/CenterText';
import SideText from '../../Shared/SideText';
import { ANIMATION_TIMINGS } from '../../../Config/Parameters';
import { sessionRepository } from '../../../Domain/Repositories/SessionRepository';
import Player from '../../../Domain/Models/API/Player';

/**
 * The scale of the team name to trigger the
 * animation of the players names.
 */
const TEAM_NAME_TRIGGER_SCALE = 0.5;

/**
 * Renders the Team Sub-Component.
 * @returns JSX.Element.
 */
export default function Teams(): JSX.Element
{
  const [layoutHeight, setLayoutHeight] = useState(0);
  const teamNameScale = useRef(new Animated.Value(0)).current;
  const playersNameScale = useRef(new Animated.Value(0)).current;
  const [scalePlayers, setScalePlayers] = useState(false);

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    /**
     * Get the largest dimension of the container.
     */
    const value = Math.max(event.nativeEvent.layout.height, event.nativeEvent.layout.width);
    setLayoutHeight(value);
    sectionHeightRepository.updateSectionHeight(SectionTypes.Teams, event);
  };

  useEffect(() =>
  {
    teamNameScale.addListener(({ value }) => onAnimationUpdated(value));

    return (): void => handleScreenDestroyed();
  }, []);

  const onAnimationUpdated = (value: number): void =>
  {
    if (value >= TEAM_NAME_TRIGGER_SCALE)
    {
      setScalePlayers(true);
    }
  };

  const handleScreenDestroyed = (): void =>
  {
    teamNameScale.removeAllListeners();
  };

  useEffect(() =>
  {
    Animated.timing(
      teamNameScale,
      {
        toValue: 1,
        duration: ANIMATION_TIMINGS.teamNameMs,
        useNativeDriver: false,
        easing: Easing.elastic(ANIMATION_TIMINGS.elasticity),
        delay: ANIMATION_TIMINGS.teamNameDelayMs,
      },
    ).start();
  }, [teamNameScale]);

  useEffect(() =>
  {
    if (!scalePlayers)
    {
      return;
    }

    Animated.timing(
      playersNameScale,
      {
        toValue: 1,
        duration: ANIMATION_TIMINGS.teamNameMs,
        useNativeDriver: false,
        easing: Easing.elastic(ANIMATION_TIMINGS.elasticity),
      },
    ).start();
  }, [playersNameScale, scalePlayers]);

  const getPointsLabel = (teamId: number): string => (teamId === -1 ? 'MAXIMUM POINTS ACHIEVABLE' : 'POINTS');

  const renderTeamData = (team?: Team): JSX.Element =>
  {
    if (team === undefined)
    {
      return (<></>);
    }

    return (
      <View
        style={styles.textContainer}
        onLayout={onLayout}
      >
        <Animated.View
          style={{
            transform: [{
              scale: teamNameScale,
            }],
          }}
        >
          <Text
            numberOfLines={1}
            style={[styles.teamName, {
              fontSize: layoutHeight * 0.1,
            }]}
          >
            {team.name}
          </Text>
        </Animated.View>
        <Animated.View
          style={{
            transform: [{
              scale: playersNameScale,
            }],
          }}
        >
          <Text
            numberOfLines={1}
            style={[styles.players, {
              fontSize: layoutHeight * 0.05,
            }]}
          >
            {team.players.map((player: Player) => player.gameTagName).join(' & ')}
          </Text>
        </Animated.View>
        <Text
          numberOfLines={1}
          style={[styles.points, {
            fontSize: layoutHeight * 0.19,
          }]}
        >
          <CountUp
            isCounting
            end={team.score}
            duration={ANIMATION_TIMINGS.teamScore}
            formatter={(value: number): string => Utils.commafyEveryThirdNumber(value)}
          />
        </Text>
        <Text
          numberOfLines={2}
          style={[styles.pointsLabel, {
            fontSize: layoutHeight * 0.05,
          }]}
        >
          {getPointsLabel(team.teamId)}
        </Text>
      </View>
    );
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.backgroundMain}>
        <View style={styles.backgroundLeft} />
        <View style={styles.backgroundRight} />
      </View>

      <View style={styles.content}>
        <SideText>
          {renderTeamData(sessionRepository.sessionData.teams[0])}
        </SideText>

        <CenterText
          showDividers
          style={{
            fontSize: layoutHeight * 0.1,
            fontFamily: Fonts.DDinCondensedBold,
          }}
          scale={new Animated.Value(1)}
        />

        <SideText>
          {renderTeamData(sessionRepository.sessionData.teams[1])}
        </SideText>
      </View>
    </View>
  ));
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.3,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
  },
  backgroundMain: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    position: 'absolute',
  },
  backgroundLeft: {
    flex: 1,
    backgroundColor: 'black',
    opacity: 0.75,
  },
  backgroundRight: {
    flex: 1,
    backgroundColor: CUBE_RED(),
    opacity: 0.85,
  },
  textContainer: {
    width: '85%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamName: {
    fontSize: SCREEN_HEIGHT * 0.03,
    fontFamily: Fonts.DDinCondensedBold,
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  players: {
    fontSize: SCREEN_HEIGHT * 0.017,
    fontFamily: Fonts.DDinExp,
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  points: {
    fontSize: SCREEN_HEIGHT * 0.06,
    fontFamily: Fonts.DDinCondensedBold,
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  pointsLabel: {
    fontSize: SCREEN_HEIGHT * 0.017,
    fontFamily: Fonts.DDinExp,
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
