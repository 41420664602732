import React from 'react';
import {
  View,
  StyleSheet,
  LayoutChangeEvent,
} from 'react-native';
import { SCREEN_HEIGHT } from '../../../Config/Constant';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import SocialSharingToolbar from '../../Shared/SocialSharingToolbar';

/**
 * Social Sharing Props.
 */
interface SocialSharingProps
{
  /**
   * Screen height.
   */
  height: number;

  /**
   * The current url.
   */
  currentUrl?: string;
}

/**
 * Renders the Social Sharing sub-component.
 * @returns JSX.Element.
 */
export default function SocialSharing({
  height,
  currentUrl = window.location.href,
}: SocialSharingProps): JSX.Element
{
  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(SectionTypes.SocialSharing, event);
  };

  return (
    <View onLayout={onLayout} style={styles.container}>
      <View style={styles.contentContainer}>
        <SocialSharingToolbar
          currentUrl={currentUrl}
          containerWidth="100%"
          height={height}
          showLabel
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.1,
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
  },
  contentContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
