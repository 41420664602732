/* eslint-disable class-methods-use-this */
import { observable, decorate } from 'mobx';
import { ScaledSize } from 'react-native';
import SessionState from '../Models/SessionState';

/**
 * App Settings Repository
 */
export class AppSettingsRepository
{
  /**
   * The device width.
   */
  public appWidth = 0;

  /**
   * The device height.
   */
  public appHeight = 0;

  /**
   * The font scale.
   */
  public appFontScale = 0;

  /**
   * The data retrieval state.
   */
  public dataRetrievalCompleted: SessionState = {
    didComplete: false,
    failed: false,
  };

  /**
   * Gets the initial screen dimensions.
   */
  public initialise(scaledSize: ScaledSize): void
  {
    this.appWidth = scaledSize.width;
    this.appHeight = scaledSize.height;
    this.appFontScale = scaledSize.fontScale;
  }
}

decorate(AppSettingsRepository, {
  appWidth: observable,
  appHeight: observable,
  appFontScale: observable,
  dataRetrievalCompleted: observable,
});

export const appSettingsRepository = new AppSettingsRepository();
