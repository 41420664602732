import axios, { AxiosResponse, AxiosError } from 'axios';
import { UpError, ErrorCode } from '../Error/UpError';
import Logger from '../Logger/Logger';
import { sessionTokenRepository } from '../Repositories/SessionTokenRepository';

const logger = Logger.Create('HttpService');
const requestTimeout = 20000;

/**
 * Handles the HTTP operations.
 */
export default class HttpService
{
  private static refreshTask?: Promise<void> = undefined;

  private static baseUrl = 'localhost';

  /**
   * Make a GET request to the server
   * @param url URL to send the request to.
   * @param specifyCustomUrl Flag to use a local server instance. If this is enabled,
   * the method will ignore the Server URL that is currently in the Parameters script.
   * You will need to provide a full URL in that case.
   */
  public static async get<T>(url: string, specifyCustomUrl = false): Promise<T>
  {
    const fullUrl = specifyCustomUrl
      ? url : this.baseUrl + url;

    logger.info(`Sending request: ${fullUrl}`);

    const response = await HttpService
      .performRequest(() => axios.get(fullUrl, {
        timeout: requestTimeout,
        headers: this.createHeader(fullUrl),
      }));

    return this.handleResponse(response);
  }

  /**
   * Make a GET request to the server without a token.
   * @param url URL to send the request to.
   * @param specifyCustomUrl If this is enabled,
   * the method will ignore the Server URL that is currently in the Parameters script.
   * You will need to provide a full URL in that case.
   */
  public static async tokenlessGet<T>(url: string, specifyCustomUrl = false): Promise<T>
  {
    const fullUrl = specifyCustomUrl
      ? url : this.baseUrl + url;

    const response = await HttpService
      .performRequest(() => axios.get(fullUrl, {
        timeout: requestTimeout,
      }));

    return this.handleResponse(response);
  }

  /**
   * Make a POST request to the server
   * @param url URL to send the request to
   * @param body Request body
   */
  public static async post<T>(url: string, body?: object, params?: object): Promise<T>
  {
    logger.info(`Sending request: ${url}`);

    const response = await HttpService
      .performRequest(() => axios.post(
        this.baseUrl + url,
        body,
        {
          timeout: requestTimeout,
          headers: this.createHeader(url),
          params,
        },
      ));

    return this.handleResponse(response);
  }

  /**
   * Make a PUT request to the server
   * @param url URL to send the request to
   * @param data Body of the request
   */
  public static async put<T>(url: string, data: object): Promise<T>
  {
    logger.info(`Sending request: ${url}`);

    const fullUrl = this.baseUrl + url;
    const response = await HttpService
      .performRequest(() => axios.put(
        fullUrl,
        data,
        {
          timeout: requestTimeout,
          headers: this.createHeader(),
        },
      ));

    return this.handleResponse(response);
  }

  /**
   * Make a DELETE request to the server
   * @param url URL to send the request to
   */
  public static async delete<T>(url: string): Promise<T>
  {
    logger.info(`Sending request: ${url}`);

    const response = await HttpService
      .performRequest(() => axios.delete(
        this.baseUrl + url,
        {
          timeout: requestTimeout,
          headers: this.createHeader(),
        },
      ));

    return this.handleResponse(response);
  }

  private static async handleResponse<T>(response: AxiosResponse): Promise<T>
  {
    if (response.status === 400)
    {
      return Promise.reject(new UpError(
        ErrorCode.BadRequest, JSON.stringify(response.data),
      ));
    }

    if (response.status === 500)
    {
      return Promise.reject(new UpError(
        ErrorCode.ServerError, JSON.stringify(response.data),
      ));
    }

    if (response.status === 404)
    {
      return Promise.reject(new UpError(
        ErrorCode.NotFound, response.data,
      ));
    }

    // Failed dependency: Used when the user cannot be found.
    if (response.status === 424)
    {
      return Promise.reject(new UpError(
        ErrorCode.UnknownUser, response.data,
      ));
    }

    if (response.status !== 200)
    {
      return Promise.reject(new UpError(
        ErrorCode.RequestError, JSON.stringify(response.data),
      ));
    }

    return response.data;
  }

  /**
   * Creates and returns a header using user token.
   */
  private static createHeader(url?: string): Record<string, string>
  {
    const accessToken = sessionTokenRepository.token;

    const header: Record<string, string> = {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'Content-Type': 'application/json',
    };

    logger.info(`Creating header for request: ${url}: ${JSON.stringify(header)}`);

    return header;
  }

  /**
   * Performs network request.
   * @param request Request packet.
   */
  private static async performRequest(request: () => Promise<AxiosResponse>): Promise<AxiosResponse>
  {
    // Attempt request
    let response = await request()
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request failed with error: ${JSON.stringify(error)}`);
          return Promise.reject(new UpError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    if (response.status === 401)
    {
      // TODO: Make a request to grab the token here...

      // Refresh the token then try again.
      if (this.refreshTask === undefined)
      {
        // TODO: Re-atttempt authentication here...
      }

      await this.refreshTask;

      response = await request()
        .catch((error: AxiosError) =>
        {
          if (error.response === undefined)
          {
            return Promise.reject(
              new UpError(ErrorCode.NoInternet, error.message),
            );
          }

          return error.response;
        });
    }

    return response;
  }
}

export const httpService = new HttpService();
