import { useHistory, useParams } from 'react-router-native';
import { Platform } from 'react-native';
import ScreenTypes from '../Types/ScreenTypes';

type NavigateToScreenType = (screenType: ScreenTypes, parameters?: {}) => void;

type usePortNavigationType = {
  navigateToScreen: NavigateToScreenType;
};

/**
 * Hook for handling navigation between the screens
 */
const useNavigation = (): usePortNavigationType =>
{
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  /**
   * Handle navigation considering parameters and platform
   */
  const navigateToScreen: NavigateToScreenType = (screenType: ScreenTypes, parameters?: {}) =>
  {
    const navigationPath = Platform.OS === 'web' ? `/${id}/${screenType}` : `/${screenType}`;

    history.push({
      pathname: navigationPath,
      state: parameters,
    });
  };

  return { navigateToScreen };
};

export default useNavigation;
