import React, { useEffect, useRef, useState } from 'react';
import {
  Platform,
  StyleSheet,
  Dimensions,
  View,
  FlatList,
  Image,
} from 'react-native';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-native';
import { ResizeMode } from 'expo-av';
import Logger from '../../Domain/Logger/Logger';
import Utils, { ValidatedUrlResult } from '../../Domain/Utils/Utils';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import useNavigation from '../../Domain/Hooks/useNavigation';
import { appSettingsRepository } from '../../Domain/Repositories/AppSettingsRepository';
import PrimaryBackground from '../../Images/Icons/PrimaryBackground.jpg';
import useGetScrollPosition from '../../Domain/Hooks/useGetScrollPosition';
import SectionTypes from '../../Domain/Types/SectionTypes';
import useGetSectionContent from '../../Domain/Hooks/useGetSectionContent';
import ScrollWindow from '../../Domain/Models/ScrollWindow';
import { sectionHeightRepository } from '../../Domain/Repositories/SectionHeightRepository';
import UpHubService from '../../Domain/Services/UpHubService';

const logger = Logger.Create('MainScreen');

/**
 * Renders the Main Screen.
 * @returns JSX.Element.
 */
export default function MainScreen(): JSX.Element
{
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { navigateToScreen } = useNavigation();
  const [portrait, setPortrait] = useState(false);
  const { currentScrollPosition, relativeScrollPosition } = useGetScrollPosition();
  const { getSectionContentArray, getSectionContent } = useGetSectionContent();
  const flatListRef = useRef<FlatList>(null);

  useEffect(() =>
  {
    const scrollWindow: ScrollWindow = {
      top: currentScrollPosition,
      bottom: currentScrollPosition + window.innerHeight,
    };

    sectionHeightRepository.onScollWindowUpdated(scrollWindow);
  },
  [currentScrollPosition,
    relativeScrollPosition,
    sectionHeightRepository.sectionHeights.length,
  ]);

  useEffect(() =>
  {
    async function initialiseApplication(): Promise<void>
    {
      // Check if the incoming URL is valid.
      const validatedUrl: ValidatedUrlResult = await Utils.isValidUrl();

      if (!validatedUrl.isValid)
      {
        logger.error('Received an invalid Url, navigating to redirect screen.');

        // Show the invalid splash screen.
        appSettingsRepository.dataRetrievalCompleted = {
          didComplete: true,
          failed: true,
        };

        navigateToScreen(ScreenTypes.Redirect);
        return;
      }

      await UpHubService.getBulletTimeData(validatedUrl.token);
    }

    if (Platform.OS === 'web')
    {
      initialiseApplication();
    }
  }, [history, id]);

  const onDimensionsChanged = (): void =>
  {
    const dimensions = Dimensions.get('screen');

    if (dimensions.width > dimensions.height)
    {
      setPortrait(false);
      return;
    }

    setPortrait(true);
  };

  useEffect(() =>
  {
    Dimensions.addEventListener('change', (): void => onDimensionsChanged());

    return (): void =>
    {
      Dimensions.removeEventListener('change', (): void => onDimensionsChanged());
    };
  }, []);

  return (
    <View style={portrait ? styles.portrait : styles.landscape}>
      <View style={styles.background}>
        <Image
          source={PrimaryBackground}
          resizeMode={ResizeMode.COVER}
          style={styles.backgroundImage}
        />
        <Image
          source={PrimaryBackground}
          resizeMode={ResizeMode.COVER}
          style={styles.backgroundImage}
        />
      </View>

      <FlatList
        ref={flatListRef}
        initialScrollIndex={0}
        contentContainerStyle={portrait ? styles.portrait : styles.landscape}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal={false}
        data={getSectionContentArray()}
        renderItem={({ item: entry }): JSX.Element => getSectionContent(entry as SectionTypes)}
        keyExtractor={(item): string => item}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  landscape: {
    flex: 1,
    height: appSettingsRepository.appWidth,
    minWidth: appSettingsRepository.appHeight,
  },
  portrait: {
    flex: 1,
    height: appSettingsRepository.appHeight,
    minWidth: appSettingsRepository.appWidth,
  },
  background: {
    position: 'absolute',
    top: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  backgroundImage: {
    minWidth: '100%',
    height: '57%',
    aspectRatio: 0.653,
    overflow: 'hidden',
  },
});
