import React from 'react';
import {
  View,
  StyleSheet,
  Image,
  LayoutChangeEvent,
} from 'react-native';
import { SCREEN_HEIGHT } from '../../../Config/Constant';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import UrbanPlaygroundIcon from '../../../Images/Icons/playground.png';

/**
 * Renders the Urban Playground Header sub-component.
 * @returns JSX.Element.
 */
export default function UrbanPlaygroundHeader(): JSX.Element
{
  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(SectionTypes.Header, event);
  };

  return (
    <View onLayout={onLayout} style={styles.container}>
      <Image
        source={UrbanPlaygroundIcon}
        resizeMode="contain"
        style={styles.headerIcon}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.035,
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
  },
  headerIcon: {
    width: undefined,
    height: '40%',
    aspectRatio: 1,
  },
});
