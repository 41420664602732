import React, { useEffect } from 'react';
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import * as ExpoSplashScreen from 'expo-splash-screen';
import { useObserver } from 'mobx-react-lite';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../Config/Constant';
import Fonts from '../../Domain/Types/Fonts';
import { appSettingsRepository } from '../../Domain/Repositories/AppSettingsRepository';
import TryAgainIcon from '../../Images/Icons/BookNowButton.png';
import UrbanPlaygroundHeader from '../Main/components/UrbanPlaygroundHeader';

/**
 * App splash screen
 * @returns JSX.Element
 */
export default function SplashScreen(): JSX.Element
{
  useEffect(() =>
  {
    ExpoSplashScreen.hideAsync();
  }, []);

  const onTryAgainSelected = (): void =>
  {
    window.location.reload();
  };

  const getSplashScreen = (): JSX.Element =>
  {
    // Disable the scroll. Flat list is useless af.
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';

    if (!appSettingsRepository.dataRetrievalCompleted.didComplete)
    {
      return (
        <View
          style={styles.container}
          pointerEvents="none"
        >
          <View style={styles.logo}>
            <UrbanPlaygroundHeader />
          </View>

          <Text style={styles.text}>
            Loading Your Story...
          </Text>
        </View>
      );
    }

    if (!appSettingsRepository.dataRetrievalCompleted.failed)
    {
      // Re-enable it once it has finished loading the data.
      document.getElementsByTagName('html')[0].style.overflow = 'scroll';
      return <></>;
    }

    return (
      <View style={styles.container}>
        <View style={styles.logo}>
          <UrbanPlaygroundHeader />
        </View>

        <Text style={styles.text}>
          Oh no! Something went wrong! Please reload the page and try again.
          If the problem persists, please try again later.
        </Text>

        <TouchableOpacity
          style={styles.tryAgainButton}
          onPress={onTryAgainSelected}
        >
          <Image
            source={TryAgainIcon}
            resizeMode="contain"
            style={styles.tryAgainIcon}
          />

          <Text style={styles.tryAgainText}>
            Retry
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return useObserver(() => (getSplashScreen()));
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    height: Dimensions.get('screen').height,
    width: Dimensions.get('screen').width,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  text: {
    fontSize: SCREEN_WIDTH * 0.033,
    textAlign: 'center',
    color: 'white',
    width: '60%',
    fontFamily: Fonts.DDinCondensedBold,
    textTransform: 'uppercase',
  },
  tryAgainIcon: {
    width: '40%',
    height: '50%',
    aspectRatio: 1,
    position: 'absolute',
    alignSelf: 'center',
    tintColor: 'red',
  },
  tryAgainButton: {
    width: '70%',
    height: '13%',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 17,
    zIndex: 20,
  },
  tryAgainText: {
    fontSize: SCREEN_HEIGHT * 0.017,
    textAlignVertical: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontFamily: Fonts.KlaptBold,
    color: 'white',
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    lineHeight: SCREEN_HEIGHT * 0.05,
  },
});
