import React from 'react';
import { Dimensions } from 'react-native';
import BulletTime from '../../Components/Main/components/BulletTime';
import CubePoints from '../../Components/Main/components/CubePoints';
import DailyLeaderboard from '../../Components/Main/components/DailyLeaderboard';
import GlobalLeaderboard from '../../Components/Main/components/GlobalLeaderboard';
import PlayerDiscount from '../../Components/Main/components/PlayerDiscount';
import SectionTitle from '../../Components/Main/components/SectionTitle';
import SocialSharing from '../../Components/Main/components/SocialSharing';
import Teams from '../../Components/Main/components/Teams';
import UrbanPlaygroundHeader from '../../Components/Main/components/UrbanPlaygroundHeader';
import YourStory from '../../Components/Main/components/YourStory';
import { sessionRepository } from '../Repositories/SessionRepository';
import SectionTypes from '../Types/SectionTypes';

/**
 * The Get Section Content type.
 */
type useGetSectionContentType = {
  getSectionContentArray: () => string[];
  getSectionContent: (item: SectionTypes) => JSX.Element;
};

/**
 * Hook which returns the section contents.
 */
const useGetSectionContent = (): useGetSectionContentType =>
{
  const getSectionContentArray = (): string[] => Object.keys(SectionTypes);

  const getSectionContent = (item: SectionTypes): JSX.Element =>
  {
    switch (item)
    {
      case SectionTypes.Header:
        return (
          <UrbanPlaygroundHeader />
        );
      case SectionTypes.YourStory:
        return (
          <YourStory
            height={Dimensions.get('screen').height}
            date={sessionRepository.sessionData.takeAwaySessionDate}
          />
        );
      case SectionTypes.Teams:
        return (
          <Teams />
        );
      case SectionTypes.CubePoints1:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints1}
            game={sessionRepository.getSessionGames(0)}
          />
        );
      case SectionTypes.CubePoints2:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints2}
            game={sessionRepository.getSessionGames(1)}
            showOverlay
          />
        );
      case SectionTypes.CubePoints3:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints3}
            game={sessionRepository.getSessionGames(2)}
          />
        );
      case SectionTypes.CubePoints4:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints4}
            game={sessionRepository.getSessionGames(3)}
            showOverlay
          />
        );
      case SectionTypes.CubePoints5:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints5}
            game={sessionRepository.getSessionGames(4)}
          />
        );
      case SectionTypes.CubePoints6:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints6}
            game={sessionRepository.getSessionGames(5)}
            showOverlay
          />
        );
      case SectionTypes.CubePoints7:
        return (
          <CubePoints
            sectionType={SectionTypes.CubePoints7}
            game={sessionRepository.getSessionGames(6)}
          />
        );
      case SectionTypes.DailyLeaderboardSectionTitle:
        return (
          <SectionTitle
            sectionType={SectionTypes.DailyLeaderboardSectionTitle}
            height={Dimensions.get('screen').height}
            title="Daily Leaderboard"
          />
        );
      case SectionTypes.DailyLeaderboard:
        return (
          <DailyLeaderboard />
        );
      case SectionTypes.PlayerDiscount:
        return (
          <PlayerDiscount
            height={Dimensions.get('screen').height}
          />
        );
      case SectionTypes.GlobalLeaderboardSectionTitle:
        return (
          <SectionTitle
            sectionType={SectionTypes.GlobalLeaderboardSectionTitle}
            height={Dimensions.get('screen').height}
            title="Global Leaderboard"
            backgroundColor="black"
          />
        );
      case SectionTypes.GlobalLeaderboard:
        return (
          <GlobalLeaderboard />
        );
      case SectionTypes.SocialSharing:
        return (
          <SocialSharing
            height={Dimensions.get('screen').height}
          />
        );
      default:
        return <></>;
    }
  };

  return { getSectionContentArray, getSectionContent };
};

export default useGetSectionContent;
