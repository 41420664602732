import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  StyleProp,
  TextStyle,
  Animated,
} from 'react-native';
import { SCREEN_WIDTH } from '../../Config/Constant';
import Fonts from '../../Domain/Types/Fonts';

/**
 * Center Text props.
 */
interface CenterTextProps
{
  /**
   * The text to render.
   */
  text?: string;

  /**
   * The text style.
   */
  style?: StyleProp<TextStyle>;

  /**
   * Toggles the dividers.
   */
  showDividers?: boolean;

  /**
   * The scale.
   */
  scale?: Animated.Value;
}

/**
 * Renders the Center Text shared sub-component.
 * @returns JSX.Element.
 */
export default function CenterText({
  text = '  VS  ',
  style,
  showDividers = false,
  scale = new Animated.Value(0),
}: CenterTextProps): JSX.Element
{
  return (
    <Animated.View style={[styles.container, {
      transform: [{
        scale,
      }],
    }]}
    >

      {showDividers && (
        <View style={styles.dash} />
      )}

      <Text style={[styles.text, style]}>
        {text}
      </Text>

      {showDividers && (
        <View style={styles.dash} />
      )}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 1.2,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  dash: {
    height: '1%',
    flex: 1,
    backgroundColor: 'white',
  },
  text: {
    fontSize: SCREEN_WIDTH * 0.05,
    fontFamily: Fonts.KlaptBold,
    color: 'white',
    textAlign: 'center',
  },
});
