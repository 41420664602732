import { LogLevel } from '@microsoft/signalr';

/**
 * The current version.
 */
export const VERSION = '0.1';

/**
 * The required URL prefix is padded in the URL.
 * This is usually followed by an id after it.
 * E.g. http://localhost:19002/#/TEMP1/TestScreen
 */
export const URL_PREFIX = 'Cube';

/**
 * Duration in milliseconds before the splash screen fades out.
 */
export const SPLASH_SCREEN_DURATION_MILLISECONDS = 1500;

/**
 * The logging level for the signalR service.
 */
export const SIGNALR_LOGLEVEL = LogLevel.Information;

/**
 * The available reconnect time in milliseconds.
 */
export const SIGNALR_RECONNECTION_MILLISECONDS = 5000;

/**
 * Disables the connection to the signalR server.
 */
export const DISABLE_SIGNALR_CONNECTION = true;

/**
 * Duration in ms for fade animation for screns appearance in ParentViewController
 */
export const FADE_ANIMATION_DURATION = 750;

/**
 * Websocket connection details.
 */
export const WEBSOCKET = {
  disabled: true,
  ipAddress: window.location.hostname,
  portNumber: 5010,
  postfix: '',
  reconnectMilliseconds: 5000,
};

export const WEBSOCKET_URL = `ws://${WEBSOCKET.ipAddress}:${WEBSOCKET.portNumber}/${WEBSOCKET.postfix}`;

export const ANIMATION_TIMINGS = {
  teamScore: 2.5,
  teamNameMs: 1000,
  teamNameDelayMs: SPLASH_SCREEN_DURATION_MILLISECONDS,
  cubeNameMs: 1000,
  cubeScore: 0.5,
  cubeLives: 0.5,
  leaderboardPoints: 0.5,
  elasticity: 1.4,
  cheat: {
    minScale: 0.85,
    maxScale: 1,
    loopTimingMs: 1000,
  },
};
