/* eslint-disable import/prefer-default-export */
import { Dimensions } from 'react-native';
import Game from '../Domain/Models/API/Game';
import Session from '../Domain/Models/API/Session';
import LeaderboardEntry from '../Domain/Models/LeaderboardEntry';
import ShareType from '../Domain/Types/ShareType';

/**
 * The default booking url when the discount is 0%.
 */
export const DefaultBookingUrl = 'https://www.urbanplayground.co.uk/attractions/manchester/the-cube';
export const UrbanPlaygroundHome = 'https://www.urbanplayground.co.uk/';

const getBlankCubeGame = (cubeName: string): Game => (
  {
    gameName: cubeName,
    leftTeamScore: 0,
    rightTeamScore: 0,
    leftTeamCheatsUsed: {
      livesUsed: 0,
      swapped: false,
      simplify: false,
    },
    rightTeamCheatsUsed: {
      livesUsed: 0,
      swapped: false,
      simplify: false,
    },
  }
);

/**
 * Empty Session.
 */
export const EmptySession: Session = {
  takeAwaySessionDate: Date.now().toLocaleString(),
  teams: [{
    teamId: 1,
    name: 'Team One',
    score: 0,
    players: [
      {
        playerId: '11',
        gameTagName: 'Player_11',
        firstname: '',
        lastName: '',
        waiverSigned: true,
      },
      {
        playerId: '12',
        gameTagName: 'Player_12',
        firstname: '',
        lastName: '',
        waiverSigned: true,
      }],
  },
  {
    teamId: 2,
    name: 'Team Two',
    score: 0,
    players: [
      {
        playerId: '21',
        gameTagName: 'Player_21',
        firstname: '',
        lastName: '',
        waiverSigned: true,
      },
      {
        playerId: '22',
        gameTagName: 'Player_22',
        firstname: '',
        lastName: '',
        waiverSigned: true,
      }],
  }],
  games: [
    getBlankCubeGame('Cube_1'),
    getBlankCubeGame('Cube_2'),
    getBlankCubeGame('Cube_3'),
    getBlankCubeGame('Cube_4'),
    getBlankCubeGame('Cube_5'),
    getBlankCubeGame('Cube_6'),
    getBlankCubeGame('Cube_7'),
  ],
  rightDailyLeaderboard: [],
  leftDailyLeaderboard: [],
  rightGlobalLeaderboard: [],
  leftGlobalLeaderboard: [],
};

/**
 * The Screen Dimensions.
 */
export const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('screen');

/**
 * The Window Dimensions.
 */
export const { width: WINDOW_WIDTH, height: WINDOW_HEIGHT } = Dimensions.get('window');

/**
 * The Share Message.
 */
export const ShareQuote = 'Did I have what it takes to beat THE CUBE?';

/**
 * Collection of share type queries.
 */
export const ShareQueries = [
  {
    shareType: ShareType.Facebook,
    query: 'https://www.facebook.com/sharer/sharer.php?&u=',
  },
  {
    shareType: ShareType.Twitter,
    query: 'https://twitter.com/intent/tweet?text=',
  },
  {
    shareType: ShareType.Email,
    query: 'mailto:?&body=',
  },
  {
    shareType: ShareType.WhatsApp,
    query: 'https://api.whatsapp.com/send?text=',
  },
];

/**
 * Returns the query string.
 * @param shareType The share type.
 * @returns The query string.
 */
export const GetFullSocialShareQuery = (shareType: ShareType, queryParameters: string): string =>
{
  const query = ShareQueries.find((x) => x.shareType === shareType);
  return query === undefined ? '' : `${query.query}${queryParameters}`;
};
