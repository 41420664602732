import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  LayoutChangeEvent,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import {
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
} from '../../../Config/Constant';
import Leaderboard from '../../Shared/Leaderboard';
import { CUBE_RED, DAILY_LEADERBOARD_PALETTE } from '../../../Config/Color';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import SocialSharingToolbar from '../../Shared/SocialSharingToolbar';
import { sessionRepository } from '../../../Domain/Repositories/SessionRepository';

/**
 * Renders the Daily Leaderboard Sub-Component.
 * @returns JSX.Element.
 */
export default function DailyLeaderboard(): JSX.Element
{
  const [sharingOpacity, setSharingOpacity] = useState(0);

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(SectionTypes.DailyLeaderboard, event);
  };

  return useObserver(() => (
    <View
      onMouseEnter={(): void => setSharingOpacity(1)}
      onMouseLeave={(): void => setSharingOpacity(0)}
      onLayout={onLayout}
      style={styles.container}
    >
      <View style={styles.content}>
        <View style={styles.contentContainer}>
          <View style={styles.leaderboardContainer}>
            <Leaderboard
              entries={sessionRepository.getDailyLeaderboard()}
              shouldAnimate={false}
            />
          </View>
        </View>
      </View>

      <View style={styles.socialSharingView}>
        <SocialSharingToolbar
          opacity={sharingOpacity}
          containerHeight="30%"
          borderRadius={SCREEN_WIDTH * 0.05}
          backgroundColor={CUBE_RED()}
        />
      </View>
    </View>
  ));
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.45,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    height: '100%',
    backgroundColor: DAILY_LEADERBOARD_PALETTE.background,
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  leaderboardContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  socialSharingView: {
    position: 'absolute',
    width: '50%',
    height: '25%',
    alignSelf: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: '1%',
  },
});
