/* eslint-disable class-methods-use-this */
import { observable, decorate } from 'mobx';
import Session from '../Models/API/Session';
import LocalStorageService from '../Services/LocalStorageService';
import SampleSessionData from '../SampleData/SessionData.json';
import Logger from '../Logger/Logger';
import { EmptySession } from '../../Config/Constant';
import LeaderboardEntry from '../Models/API/LeaderboardEntry';
import Game from '../Models/API/Game';

const logger = Logger.Create('SessionRepository');

/**
 * Session Repository
 */
export class SessionRepository
{
  /**
   * The session data.
   */
  public sessionData: Session = EmptySession;

  protected sessionDataStorage: LocalStorageService<Session> = new LocalStorageService<Session>('SessionRepository');

  /**
   * Initialises the repository with the session data.
   */
  public async initialise(session: Session): Promise<void>
  {
    await this.updateRepository(session);
  }

  /**
   * Returns a non-duplicate daily leaderboard.
   */
  public getDailyLeaderboard(): LeaderboardEntry[]
  {
    return this.getLeaderboard(this.sessionData.leftDailyLeaderboard,
      this.sessionData.rightDailyLeaderboard);
  }

  /**
   * Returns a non-duplicate global leaderboard.
   */
  public getGlobalLeaderboard(): LeaderboardEntry[]
  {
    return this.getLeaderboard(this.sessionData.leftGlobalLeaderboard,
      this.sessionData.rightGlobalLeaderboard);
  }

  /**
   * Returns the session game.
   * @param session The session number.
   */
  public getSessionGames(session: number): Game | undefined
  {
    if (session > this.sessionData.games.length - 1)
    {
      return undefined;
    }

    return this.sessionData.games[session];
  }

  private getLeaderboard(
    leaderboardOne: LeaderboardEntry[],
    leaderboardTwo: LeaderboardEntry[],
  ): LeaderboardEntry[]
  {
    // Combines two entries and removes the duplicates.
    const positions = new Set(leaderboardOne.map(
      (item) => item.position,
    ));

    const combinedEntries = [...leaderboardOne,
      ...leaderboardTwo.filter(
        (item) => !positions.has(item.position),
      )];

    // Sort the entries by position.
    return combinedEntries.sort((a: LeaderboardEntry, b: LeaderboardEntry) =>
    {
      if (a.position < b.position)
      {
        return -1;
      }

      if (a.position > b.position)
      {
        return 1;
      }

      return 0;
    });
  }

  private async updateRepository(data: Session): Promise<void>
  {
    this.sessionData = data;

    logger.info('Successfully saved a local instance of the session data.');
    await this.sessionDataStorage.save(data)
      .then((): void =>
      {
        logger.info('Successfully saved the session data to local storage.');
      })
      .catch((error): void =>
      {
        logger.error(`Failed to save the data to local storage with the following error: ${error}`);
      });
  }
}

decorate(SessionRepository, {
  sessionData: observable,
});

export const sessionRepository = new SessionRepository();
