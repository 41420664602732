import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  LayoutChangeEvent,
  Image,
  ImageSourcePropType,
  Animated,
  Easing,
} from 'react-native';
import { CountUp } from 'use-count-up';
import { SCREEN_HEIGHT } from '../../../Config/Constant';
import Fonts from '../../../Domain/Types/Fonts';
import CenterText from '../../Shared/CenterText';
import SideText from '../../Shared/SideText';
import SimplifyIcon from '../../../Images/Icons/Simplify.png';
import SwapIcon from '../../../Images/Icons/Swap.png';
import CheatType from '../../../Domain/Types/CheatType';
import { CUBE_RED } from '../../../Config/Color';
import SectionTypes from '../../../Domain/Types/SectionTypes';
import { sectionHeightRepository } from '../../../Domain/Repositories/SectionHeightRepository';
import Utils from '../../../Domain/Utils/Utils';
import { ANIMATION_TIMINGS } from '../../../Config/Parameters';
import Game from '../../../Domain/Models/API/Game';
import CheatsUsed from '../../../Domain/Models/API/CheatsUsed';

/**
 * Cube Points Props.
 */
interface CubePointsProps
{
  /**
   * The cube points data.
   */
  game?: Game;

  /**
   * Toggles the white background overlay.
   */
  showOverlay?: boolean;

  /**
   * The section type.
   */
  sectionType: SectionTypes;
}

/**
 * The scale of the cube name
 * to start the cube score counter.
 */
const CUBE_NAME_TRIGGER_SCALE = 0.2;

/**
 * Renders the Cube Point Sub-Component.
 * @returns JSX.Element.
 */
export default function CubePoints({
  sectionType,
  game,
  showOverlay = false,
}: CubePointsProps): JSX.Element
{
  const scale = useRef(new Animated.Value(0)).current;
  const [layoutHeight, setLayoutHeight] = useState(0);
  const [startCounting, setStartCounting] = useState(false);
  const [scaleStarted, hasScaleStarted] = useState(false);
  const cheatAnimation = useRef(new Animated.Value(ANIMATION_TIMINGS.cheat.maxScale)).current;

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    sectionHeightRepository.updateSectionHeight(sectionType, event);

    /**
     * Get the largest dimension of the container.
     */
    const value = Math.min(event.nativeEvent.layout.height, event.nativeEvent.layout.width);

    setLayoutHeight(value);
  };

  const handleScreenDestroyed = (): void =>
  {
    scale.removeAllListeners();
  };

  useEffect(() =>
  {
    if (game === undefined)
    {
      return (): void => handleScreenDestroyed();
    }

    scale.addListener(({ value }) => onAnimationUpdated(value));

    return (): void => handleScreenDestroyed();
  }, []);

  const onAnimationUpdated = (value: number): void =>
  {
    if (value >= CUBE_NAME_TRIGGER_SCALE)
    {
      setStartCounting(true);
    }
  };

  useEffect(() =>
  {
    if (game === undefined)
    {
      return;
    }

    if (scaleStarted)
    {
      return;
    }

    if (sectionHeightRepository.sectionsInView.includes(sectionType))
    {
      hasScaleStarted(true);
      Animated.timing(
        scale,
        {
          toValue: 1,
          duration: ANIMATION_TIMINGS.cubeNameMs,
          useNativeDriver: false,
          easing: Easing.elastic(ANIMATION_TIMINGS.elasticity),
        },
      ).start();
    }
  }, [scale, sectionHeightRepository.sectionsInView.includes(sectionType)]);

  /**
   * UseEffect for looping the cheat icon.
   */
  useEffect(() =>
  {
    if (game === undefined)
    {
      return;
    }

    Animated.loop(
      Animated.sequence([
        Animated.timing(cheatAnimation, {
          toValue: ANIMATION_TIMINGS.cheat.minScale,
          duration: ANIMATION_TIMINGS.cheat.loopTimingMs,
          useNativeDriver: false,
        }),
        Animated.timing(cheatAnimation, {
          toValue: ANIMATION_TIMINGS.cheat.maxScale,
          duration: ANIMATION_TIMINGS.cheat.loopTimingMs,
          useNativeDriver: false,
        }),
      ]),
    ).start();
  }, []);

  const getLivesUsedText = (currentLives: number): string => `${Math.round(currentLives)} lives used`;

  const getCheatIcon = (cheat: CheatType): ImageSourcePropType => (
    cheat === CheatType.Simplify ? SimplifyIcon : SwapIcon);

  const getCheatIcons = (cheatsUsed?: CheatsUsed): JSX.Element =>
  {
    const icons: JSX.Element[] = [];

    if (cheatsUsed?.simplify)
    {
      icons.push(
        <Image
          source={getCheatIcon(CheatType.Simplify)}
          resizeMode="contain"
          style={styles.icon}
          key={`${game?.gameName}_simplify`}
        />,
      );
    }

    if (cheatsUsed?.swapped)
    {
      icons.push(
        <Image
          source={getCheatIcon(CheatType.Swap)}
          resizeMode="contain"
          style={styles.icon}
          key={`${game?.gameName}_swap`}
        />,
      );
    }

    return (
      <>
        {icons}
      </>
    );
  };

  const getLivesUsed = (cheatsUsed?: CheatsUsed): number =>
  {
    if (cheatsUsed === undefined || cheatsUsed === null)
    {
      return 0;
    }

    return cheatsUsed.livesUsed;
  };

  const renderTeamData = (
    points?: number,
    cheatsUsed?: CheatsUsed,
    isRight = false,
  ): JSX.Element => (
    <View
      style={[styles.textContainer, {
        flexDirection: isRight ? 'row' : 'row-reverse',
        justifyContent: 'center',
      }]}
      onLayout={onLayout}
    >
      <View style={styles.leftPadding} />

      <View style={styles.pointsView}>
        <View style={styles.pointsContent}>
          <Text
            style={[styles.points, {
              fontSize: layoutHeight * 0.8,
            }]}
          >
            <CountUp
              isCounting={startCounting}
              start={0}
              end={points}
              duration={ANIMATION_TIMINGS.cubeScore}
              decimalPlaces={1}
              formatter={(value: number): string => Utils.commafyEveryThirdNumber(value)}
            />
          </Text>
        </View>

        <Text
          numberOfLines={3}
          style={[styles.points, {
            fontSize: layoutHeight * 0.22,
          }]}
        >
          {(cheatsUsed !== null) && (
            <CountUp
              isCounting={startCounting}
              start={0}
              end={getLivesUsed(cheatsUsed)}
              duration={ANIMATION_TIMINGS.cubeLives}
              decimalPlaces={1}
              formatter={(value: number): string => getLivesUsedText(value)}
            />
          )}
          {(cheatsUsed === null) && (
            'Maximum Points Achievable'
          )}
        </Text>
      </View>

      <Animated.View style={[styles.cheatView, {
        transform: [{
          scale: cheatAnimation,
        }],
      }]}
      >
        {getCheatIcons(cheatsUsed)}
      </Animated.View>
    </View>
  );

  if (game === undefined)
  {
    return <></>;
  }

  return (
    <View style={styles.container}>
      <View style={styles.backgroundMain}>
        <View style={styles.backgroundLeft} />
        <View style={styles.backgroundRight} />
      </View>

      <View style={[styles.backgroundMainOverlay, {
        opacity: showOverlay ? 0.05 : 0,
      }]}
      />

      <View style={styles.content}>
        <View style={styles.topPadding} />

        <CenterText
          style={[styles.centerText, {
            fontSize: layoutHeight * 0.58,
          }]}
          scale={scale}
          text={game?.gameName}
        />

        <View style={styles.contentContainer}>
          <SideText>
            {renderTeamData(game?.leftTeamScore, game?.leftTeamCheatsUsed, true)}
          </SideText>

          <SideText>
            {renderTeamData(game?.rightTeamScore, game?.rightTeamCheatsUsed)}
          </SideText>
        </View>

        <View style={styles.bottomPadding} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: SCREEN_HEIGHT * 0.14,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  backgroundMain: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    position: 'absolute',
  },
  backgroundLeft: {
    flex: 1,
    backgroundColor: 'black',
    opacity: 0.75,
  },
  backgroundRight: {
    flex: 1,
    backgroundColor: CUBE_RED(),
    opacity: 0.85,
  },
  backgroundMainOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    opacity: 0.05,
  },
  content: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    flex: 1,
  },
  textContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  points: {
    fontFamily: Fonts.KlaptBold,
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  topPadding: {
    flex: 0.3,
  },
  centerText: {
    textTransform: 'uppercase',
    flex: 1,
    justifyContent: 'flex-start',
    paddingBottom: '0.8%',
  },
  bottomPadding: {
    flex: 0.7,
  },
  leftPadding: {
    flex: 1,
  },
  pointsView: {
    flex: 2,
    justifyContent: 'center',
  },
  pointsContent: {
    width: '100%',
    height: '100%',
  },
  cheatView: {
    flex: 1.35,
    alignItems: 'flex-start',
    height: '52%',
  },
  icon: {
    width: '70%',
    height: '100%',
    aspectRatio: 1,
    alignSelf: 'center',
  },
});
