/* eslint-disable no-new */
import * as Font from 'expo-font';
import Logger from '../Domain/Logger/Logger';

const logger = Logger.Create('FontHandler');

/**
 * Font Handler
 */
export default class FontHandler
{
  /**
   * Loads all the required fonts. This should be called at Root
   * during the initialisation phase.
   */
  public static async initialise(): Promise<boolean>
  {
    logger.info('Loading all fonts...');
    await Font.loadAsync({
      KlaptBold: {
        uri: require('../Fonts/Klapt/Klapt-Bold.otf'),
      },
      KlaptMedium: {
        uri: require('../Fonts/Klapt/Klapt-Medium.otf'),
      },
      DDin: {
        uri: require('../Fonts/DDin/DDin.otf'),
      },
      DDinCondensed: {
        uri: require('../Fonts/DDin/DDinCondensed.otf'),
      },
      DDinCondensedBold: {
        uri: require('../Fonts/DDin/DDinCondensedBold.otf'),
      },
      DDinExp: {
        uri: require('../Fonts/DDin/DDinExp.otf'),
      },
    })
      .then(() =>
      {
        logger.info('Successfully loaded all the fonts required!');
      })
      .catch((error) =>
      {
        logger.info(`Failed to load the requested font because of the error ${error}. The application may not display the content correctly.`);
      });

    return true;
  }
}
