/**
 * The Share Types.
 */
enum ShareType
{
  CopyLink = 'CopyLink',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Email = 'Email',
  WhatsApp = 'WhatsApp',
}

export default ShareType;
