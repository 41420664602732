import AsyncStorage from '@react-native-async-storage/async-storage';
import { ErrorCode, UpError } from '../Error/UpError';

/**
 * Service for storing data in local storage.
 */
export default class LocalStorageService<T>
{
  private key: string;

  constructor(key: string)
  {
    this.key = key;
  }

  /**
   * Load from local storage.
   */
  public async load(): Promise<T | undefined>
  {
    const json = await AsyncStorage.getItem(this.key)
      .catch((error: Error) => Promise.reject(
        new UpError(ErrorCode.StorageError, error.message),
      ));

    if (json === null)
    {
      return undefined;
    }

    return JSON.parse(json);
  }

  /**
   * Save to local storage.
   */
  public async save(value: T): Promise<void>
  {
    return AsyncStorage.setItem(this.key, JSON.stringify(value))
      .catch((error: Error) => Promise.reject(
        new UpError(ErrorCode.StorageError, error.message),
      ));
  }

  /**
   * Clear the local storage.
   */
  public async clear(): Promise<void>
  {
    return AsyncStorage.removeItem(this.key)
      .catch((error: Error) => Promise.reject(
        new UpError(ErrorCode.StorageError, error.message),
      ));
  }
}
