/* eslint-disable no-plusplus */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Text as RNText,
  View,
  StyleSheet,
  LayoutChangeEvent,
} from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { CountUp } from 'use-count-up';
import { DAILY_LEADERBOARD_PALETTE, LeaderboardColorPalette } from '../../Config/Color';
import { SCREEN_WIDTH } from '../../Config/Constant';
import { ANIMATION_TIMINGS } from '../../Config/Parameters';
import LeaderboardEntry from '../../Domain/Models/LeaderboardEntry';
import Fonts from '../../Domain/Types/Fonts';
import Utils from '../../Domain/Utils/Utils';

/**
 * The leaderboard props.
 */
interface LeaderboardProps
{
  /**
   * The leaderboard entries.
   */
  entries: LeaderboardEntry[];

  /**
   * The colour palette.
   */
  colorPalette?: LeaderboardColorPalette;

  /**
   * Should animate the scores.
   */
  shouldAnimate?: boolean;
}

/**
 * Renders the Leaderboard.
 */
export default function Leaderboard({
  entries = [],
  colorPalette = DAILY_LEADERBOARD_PALETTE,
  shouldAnimate = false,
}: LeaderboardProps): JSX.Element
{
  /**
   * The original SVG dimensions that is originally
   * encapsulated within the SVG's viewbox.
   */
  const originalSvgWidthPixels = 745;
  const originalSvgHeightPixels = 529;
  const originalAspectRatio = originalSvgWidthPixels / originalSvgHeightPixels;
  const [fontSize, setFontSize] = useState<number>(20);

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    const calculatedFontSize = (event.nativeEvent.layout.height * 0.6) / 10;
    setFontSize(calculatedFontSize);
  };

  const [positions, setPositions] = useState<number[]>([]);

  // TODO: Get this from API
  const teamNames = ['jorvin', 'jindy'];

  const getBackgroundColor = (i: number): string =>
  {
    if (positions.includes(i))
    {
      return colorPalette.selected;
    }

    return i % 2 === 0 ? colorPalette.odd : colorPalette.even;
  };

  const getTextColor = (i: number): string => (positions.includes(i)
    ? colorPalette.textSelected : colorPalette.textNormal);

  const getTopSvgItemColor = (): string => (positions.includes(0)
    ? colorPalette.selected : colorPalette.odd);

  const getBottomSvgItemColor = (): string =>
  {
    if (positions.includes(entries.length - 1))
    {
      return colorPalette.selected;
    }

    return entries.length % 2 === 0 ? colorPalette.even : colorPalette.odd;
  };

  useEffect(() =>
  {
    const teamPositions = [];

    for (let i = 0; i < teamNames.length; i++)
    {
      const index: number = entries.findIndex(
        (x: LeaderboardEntry) => x.name.toLowerCase() === teamNames[i].toLowerCase(),
      );

      teamPositions.push(index);
    }

    setPositions(teamPositions);
  }, []);

  const renderItems = (): JSX.Element[] =>
  {
    const items: JSX.Element[] = [];

    for (let i = 0; i < entries.length; i++)
    {
      if (i === Math.round(entries.length / 2) && entries.length > 5)
      {
        items.push(
          <View
            style={styles.itemSeparator}
            key={`Middle: ${i}`}
          />,
        );
      }

      items.push(
        <View
          style={{
            backgroundColor: getBackgroundColor(i),
            flex: 1,
            borderTopLeftRadius: i === 0 ? SCREEN_WIDTH * 0.03 : undefined,
            borderBottomRightRadius: i === entries.length - 1 ? SCREEN_WIDTH * 0.03 : undefined,
          }}
          key={i}
        >
          <View style={styles.leaderboardItem}>
            <RNText
              style={[styles.text, {
                color: getTextColor(i),
                fontSize,
                flex: 1,
                textAlign: 'left',
              }]}
            >
              {entries[i].position}
            </RNText>

            <RNText
              style={[styles.text, {
                color: getTextColor(i),
                fontSize,
                flex: 4,
                textAlign: 'center',
                width: '50%',
              }]}
            >
              {entries[i].name}
            </RNText>

            <RNText
              style={[styles.text, {
                color: getTextColor(i),
                fontSize,
                textAlign: 'right',
                flex: 1,
              }]}
            >
              {shouldAnimate && (
                <CountUp
                  isCounting={shouldAnimate}
                  end={parseInt(entries[i].score, 10)}
                  duration={ANIMATION_TIMINGS.leaderboardPoints}
                  formatter={(value: number): string => Utils.commafyEveryThirdNumber(value)}
                />
              )}
              {!shouldAnimate && (
                parseInt(entries[i].score, 10)
              )}
            </RNText>
          </View>
        </View>,
      );
    }

    return items;
  };

  return (
    <View
      style={[styles.root, {
        aspectRatio: originalAspectRatio,
        overflow: 'hidden',
      }]}
    >
      {/** Wrap SVG in its own View so it follows the size of the content */}
      <View onLayout={onLayout}>
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignSelf: 'center',
            overflow: 'hidden',
          }}
        >
          {renderItems()}
        </View>

        <Svg
          viewBox={`0 0 ${originalSvgWidthPixels} ${originalSvgHeightPixels}`}
          width="100%"
          height="100%"
        >
          <Path fill="white" d="M0 25h745v476H0z" />
          <Path fill={getTopSvgItemColor()} d="M14.173 0H745v67H0V14.173L14.173 0" />
          <Path fill={getBottomSvgItemColor()} d="M0 462h745v52.827L730.827 529H0Z" />
        </Svg>
      </View>
    </View>

  );
}

const styles = StyleSheet.create({
  root: {
    height: '85%',
    maxWidth: '90%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  leaderboardItem: {
    flex: 1,
    width: '93%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  itemSeparator: {
    backgroundColor: 'black',
    flex: 0.1,
  },
  text: {
    fontFamily: Fonts.KlaptBold,
    textTransform: 'uppercase',
  },
});
