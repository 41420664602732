/* eslint-disable import/prefer-default-export */

/**
 * The leaderboard colour palette interface.
 */
export interface LeaderboardColorPalette
{
  /**
   * Odd item color.
   */
  odd: string;

  /**
   * Even item color.
   */
  even: string;

  /**
   * Selected item color.
   */
  selected: string;

  /**
   * Normal text color.
   */
  textNormal: string;

  /**
   * Selected text color.
   */
  textSelected: string;

  /**
   * The leaderboard background color.
   */
  background: string;
}

export const PURPLE = 'rgb(255, 0, 210)';

export const ELECTRIC_BLUE = 'rgb(23, 255, 254)';

export const CUBE_RED = (opacity = 1): string => `rgba(177, 38, 26, ${opacity})`;

export const DAILY_LEADERBOARD_PALETTE: LeaderboardColorPalette = {
  odd: 'white',
  even: '#E5E3E3',
  selected: CUBE_RED(),
  textNormal: 'black',
  textSelected: 'white',
  background: 'rgba(0, 0, 0, 0.85)',
};

export const GLOBAL_LEADERBOARD_PALETTE: LeaderboardColorPalette = {
  odd: 'white',
  even: '#E5E3E3',
  selected: 'black',
  textNormal: 'black',
  textSelected: 'white',
  background: CUBE_RED(0.85),
};
