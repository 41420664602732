/**
 * The Cheat Type.
 */
enum CheatType
{
  /**
   * Simplify.
   */
  Simplify = 'Simplify',

  /**
   * Swap.
   */
  Swap = 'Swap',
}

export default CheatType;
